
import { defineComponent, onMounted, ref } from 'vue';
import { IonPage } from '@ionic/vue';
import { useRoute } from 'vue-router';

import { merchants } from '@/store';
import DeliveryStatus from '@/components/shared/DeliveryStatus.vue';
import { Job } from '@/types/merchant';
import { loading } from '@/composables/ionicControllers';

export default defineComponent({
  components: {
    DeliveryStatus,
    IonPage,
  },
  setup() {
    const selectedJob = ref<Job | null>(null);
    const { getJobById } = merchants;

    const route = useRoute();

    const jobId = route.params.id as string;

    onMounted(async () => {
      const loader = await loading();
      await loader.present();

      const result = await getJobById(atob(jobId));
      if (result.ok) {
        console.log(result);
        selectedJob.value = result.data as Job;
      }
      loader.dismiss();
    });

    return {
      selectedJob,
    };
  },
});
