import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delivery_status = _resolveComponent("delivery-status")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.selectedJob)
        ? (_openBlock(), _createBlock(_component_delivery_status, {
            key: 0,
            delivery: _ctx.selectedJob
          }, null, 8, ["delivery"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}